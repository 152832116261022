@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;600;700&family=Source+Sans+3:ital,wght@0,400;0,600;1,400;1,600&display=swap");

@layer base {

    html {
        scroll-behavior: smooth;
    }

    body {
        font-family: "Source Sans 3", arial, sans-serif;
        @apply bg-gray-100;
    }

    ::selection {
        @apply bg-orange;
    }

    li {
        @apply ml-4;
    }

    ul li {
        @apply list-disc list-inside; 
    }

    ol li {
        @apply list-decimal list-inside; 
    }
}

@layer components {

    .link {
        @apply text-primary underline;
    }

    .block-link {
        @apply font-semibold bg-[#d3e5ef] px-6 py-3 rounded hover:bg-[#c0d8e4];
    }

    .page-width {
        @apply max-w-7xl mx-auto px-6 md:px-10 lg:px-16;
    }

    .heading {
        @apply font-league font-bold border-b text-3xl border-gray-300 pb-2 sm:text-4xl sm:pb-4;
    }

}